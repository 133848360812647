<template>
  <VDialog
    :value="isOrderDialogOpen"
    width="100%"
    max-width="800px"
    @update:returnValue="close"
  >
    <VCard color="white" class="mx-auto" max-height="90vh">
      <VCardTitle class="pb-0">
        <VFlex xs12 mb-2 text-xs-center>
          <h1>Order Online</h1>
        </VFlex>
      </VCardTitle>
      <VCardText class="font-weight-medium py-2">
        <div class="text-xs-center mx-3">
          <h2 class="r-title">
            <transition name="fade-fast" mode="out-in">
              <span v-if="!orderDialogSelectedLocation" key="instrux-location"
                >First, choose a location...</span
              >
              <span v-else key="instrux-ordering-option"
                >Select an ordering option</span
              >
            </transition>
          </h2>
        </div>
        <WithLocationSummaries v-slot="{ locations }">
          <VLayout
            class="text-xs-center"
            :class="[['xs', 'sm'].includes($mq) ? 'pa-2' : 'pa-3']"
          >
            <transition name="fade-fast" mode="out-in">
              <VFlex v-if="orderDialogSelectedLocation" key="selected-loc">
                <div class="header-2 bubs-purple--text font-weight-bold">{{
                  orderDialogSelectedLocation.title
                }}</div>

                <VLayout
                  v-if="orderDialogSelectedLocation.ordering_options"
                  class="mb-2 justify-center"
                  wrap
                >
                  <BaseButton
                    v-for="opt in getOrderingOptions(
                      orderDialogSelectedLocation
                    )"
                    :key="`${orderDialogSelectedLocation.id}-${opt.title}`"
                    :href="opt.link"
                    color="#CA6FDA"
                    large
                  >
                    {{ opt.title }}
                  </BaseButton>
                </VLayout>
                <div v-else class="r-headline font-italic my-4"
                  >We couldn't find any ordering options for this location.</div
                >
                <BaseButtonOutline
                  outline-color="#29241A"
                  md-icon="arrow_left"
                  md-icon-position="left"
                  @click="orderDialogSelectedLocation = null"
                >
                  {{ $mq === 'xs' ? 'Back' : 'Select another Location' }}
                </BaseButtonOutline>
              </VFlex>
              <VContainer v-else key="choose-a-loc" grid-list-xl>
                <VLayout row wrap>
                  <VFlex
                    v-for="loc in locations"
                    :key="loc.id"
                    xs12
                    sm6
                    @click="orderDialogSelectedLocation = loc"
                  >
                    <VCard
                      class="location-card bubs-purple white--text pa-2 elevation-0"
                    >
                      <VCardText>
                        <h2 class="r-headline">{{ loc.title }}</h2>
                      </VCardText>
                    </VCard>
                  </VFlex>
                </VLayout></VContainer
              >
            </transition>
          </VLayout>
        </WithLocationSummaries>
        <BaseButton
          class="ml-0 close-popup-btn"
          color="grey"
          absolute
          bottom
          right
          @click="close"
          >Close</BaseButton
        >
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import VDialog from '@vuetify/VDialog'
import { VCardText } from '@vuetify/VCard'

export default {
  name: 'BubsOrderDialog',
  components: {
    VDialog,
    VCardText,
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields('nav', ['isOrderDialogOpen', 'orderDialogSelectedLocation']),
  },
  methods: {
    close() {
      this.isOrderDialogOpen = false
      this.orderDialogSelectedLocation = null
    }, // close
    getOrderingOptions(location) {
      return typeof location.ordering_options === 'object'
        ? location.ordering_options
        : JSON.parse(location.ordering_options)
    },
  },
}
</script>

<style lang="css">
.location-card {
  cursor: pointer;
}
.v-btn--absolute.close-popup-btn {
  bottom: 10px !important;
}
</style>
